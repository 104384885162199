import { formatDistance, parseISO } from 'date-fns';

const formatNumber = function (input: string | number | null, minimumFractionDigits = 2, maximumFractionDigits = 2, useGrouping = false): string {
    if (typeof input === 'string') input = parseFloat(input);
    if (input === null) input = 0;

    const numberFormatter = new Intl.NumberFormat(navigator.language, {
        minimumFractionDigits,
        maximumFractionDigits,
        useGrouping,
    });

    return numberFormatter.format(input);
};

const formatCurrency = function (input: string | number | null, isoCode: string, minimumFractionDigits = 2, maximumFractionDigits = 2, useGrouping = false): string {
    const parsedInput = parseFloat((input ?? '').toString());

    const currencyFormatter = new Intl.NumberFormat(navigator.language, {
        style: 'currency',
        currency: isoCode,
        minimumFractionDigits,
        maximumFractionDigits,
        useGrouping,
    });

    return currencyFormatter.format(parsedInput);
};

const formatAgo = function (date: string|Date, locale: Locale): string {
    if (typeof date === 'string') {
        date = parseISO(date);
    }

    return formatDistance(
        new Date(date),
        new Date(),
        { addSuffix: true, locale }
    );
};

export { formatNumber, formatCurrency, formatAgo };
