














import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { TranslateMixin } from '@/util/translate';

const DEFAULT_ICON = 'fa-info-circle';

@Component
export default class FFormHelpText extends Mixins(TranslateMixin) {
    @Prop({ required: true, type: String })
    text!: string;

    @Prop({ required: false })
    icon!: string;

    get helpIcon(): string {
        if (this.icon) {
            return this.icon;
        }

        return DEFAULT_ICON;
    }
}
