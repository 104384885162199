
















































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { TranslateMixin } from '@/util/translate';
import { ValidationProvider } from 'vee-validate';
import FFormItem from '@/components/form/f-form-item.vue';
import VueSelectize from '@/components/vue-selectize';
import FormElementMixin from '@/components/form/FormElementMixin.vue';
import FFormHelpText from '@/components/form/f-form-help-text.vue';

@Component({
    components: { VueSelectize, ValidationProvider, FFormItem, FFormHelpText }
})
// TODO: @change-object in template might need to be removed, investigate and confirm.
export default class FSelect extends Mixins(FormElementMixin, TranslateMixin) {
    $refs!: {
        dropdown: VueSelectize,
    };

    @Prop()
    id!: string

    @Prop()
    value!: string | string[] | null;

    @Prop({ type: Array })
    options!: Array<any>;

    @Prop({ type: String, default: 'id' })
    optionValue!: string;

    @Prop({ type: String, default: 'name' })
    optionLabel?: string;

    @Prop({ type: Boolean, default: false })
    translated!: boolean;

    @Prop({ type: String, default: 'Please select' })
    placeholder!: string;

    @Prop({ required: false, default: '' })
    searchField: string|string[];

    @Prop({ required: false, default: null })
    disabledField: string|null;

    @Prop({ type: String, default: null, required: false })
    editLinkRoute!: string | null;

    @Prop({ type: Object, default: null, required: false })
    listEntityLink!: ListEntityLink|null;

    @Prop({ type: Object, default: null, required: false })
    updateEntityLink!: UpdateEntityLink|null;

    @Prop({ type: Boolean, default: false, required: false })
    allowCreate!: boolean;

    @Prop({ type: Boolean, default: false, required: false })
    lazyLoaded!: boolean;

    @Prop({ type: String, default: 'onClick', required: false })
    lazyLoadTrigger!: string;

    innerValue?: string | string[] = '';

    mounted() {
        if (typeof this.value === 'string') {
            this.innerValue = this.value;
        }
        if (this.value !== null && Symbol.iterator in Object(this.value)) {
            this.innerValue = this.value;
        }
    }

    public getInputElement(): HTMLInputElement|null {
        return document.getElementById(this.$refs.dropdown.id) as HTMLInputElement;
    }

    @Watch('innerValue')
    onInnerValueChanged(newVal: string | string[], oldVal: string | string[]) {
        this.$emit('input', newVal);
        this.$emit('change-object', this.options.find(opt => opt[this.optionValue] == newVal));
    }

    @Watch('value')
    onValueChanged(newVal: string | string[]) {
        this.innerValue = newVal;
    }
}
