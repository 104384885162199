var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.text
    ? _c(
        "p",
        {
          staticClass: "help-block text-info"
        },
        [
          _vm.helpIcon
            ? _c("span", {
                staticClass: "fa fa-fw",
                class: _vm.helpIcon
              })
            : _vm._e(),
          _vm._v("\n    " + _vm._s(_vm.text) + "\n")
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }