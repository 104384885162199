var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("validation-provider", {
    attrs: {
      tag: "div",
      vid: _vm.vid,
      name: _vm.$t(_vm.$attrs.label),
      rules: _vm.rules
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(_ref) {
            var errors = _ref.errors
            return _c(
              "f-form-item",
              {
                attrs: {
                  "error-messages": errors,
                  required: _vm.$attrs.required,
                  "show-label": false,
                  "no-space": _vm.noSpace
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "form-check"
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.innerValue,
                          expression: "innerValue"
                        }
                      ],
                      ref: "input",
                      class: {
                        "form-check-input": !_vm.static
                      },
                      style: {
                        width: _vm.big ? "20px" : _vm.size,
                        height: _vm.big ? "20px" : _vm.size
                      },
                      attrs: {
                        id: _vm.uniqueId,
                        type: "checkbox",
                        disabled: _vm.disabled
                      },
                      domProps: {
                        checked: Array.isArray(_vm.innerValue)
                          ? _vm._i(_vm.innerValue, null) > -1
                          : _vm.innerValue
                      },
                      on: {
                        click: function() {
                          return _vm.$emit("click", !_vm.innerValue)
                        },
                        change: function($event) {
                          var $$a = _vm.innerValue,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false

                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)

                            if ($$el.checked) {
                              $$i < 0 && (_vm.innerValue = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.innerValue = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.innerValue = $$c
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.$attrs.label
                      ? _c(
                          "label",
                          {
                            staticClass: "form-check-label",
                            attrs: {
                              for: _vm.uniqueId
                            }
                          },
                          [
                            _vm._t("default", [
                              _vm._v(_vm._s(_vm.$t(_vm.$attrs.label)))
                            ])
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                )
              ]
            )
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }