import { createEventDefinition } from 'ts-bus';

const createLazyLoadingStateEventForId = (id: String) => createEventDefinition<>()('@scale.form.loading_state_id:' + id)

const createSetLazyLoadedChoicesEventForId = (id: String) => createEventDefinition<{
    choices: any[]
}>()('@scale.form.set_lazy_loaded_id:' + id)



export {
    createSetLazyLoadedChoicesEventForId,
    createLazyLoadingStateEventForId
}
