import { EventBus } from 'ts-bus';

/**
 * Gebruik deze event bus voor al uw feesten en partijen.
 *
 * Houd deze notatie aan voor event namen a.u.b.: "@domain.eventName" of "@domain.nestedDomain.eventName"
 * Plaats alle events in de "/events" map
 *
 * @see https://github.com/ryardley/ts-bus
 */
const foodEventBus = new EventBus();

export default foodEventBus;
