





































import { Component, Prop, Mixins, Watch, Ref } from 'vue-property-decorator';
import { TranslateMixin } from '@/util/translate';
import { ValidationProvider } from 'vee-validate';
import FFormItem from '@/components/form/f-form-item.vue';
import { pick, uniqueId } from 'lodash';

@Component({
    components: { ValidationProvider, FFormItem }
})
export default class FCheckbox extends Mixins(TranslateMixin) {
    @Ref()
    input!: HTMLInputElement;

    @Prop({ type: String })
    vid!: string;

    @Prop({ type: [Object, String ] })
    rules!: string | object;

    @Prop({ type: Boolean, required: false, default: false })
    value!: boolean;

    @Prop({ type: Boolean, required: false, default: false })
    disabled!: boolean;

    @Prop({ type: Boolean, default: false })
    noSpace!: boolean;

    @Prop({ type: Boolean, default: false })
    static!: boolean;

    @Prop({ type: Boolean, default: false })
    big!: boolean;

    @Prop({ type: String, default: 'initial' })
    size!: string;

    innerValue = false;
    uniqueId = uniqueId('checkbox_');

    mounted() {
        if (!this.innerValue && this.value) {
            this.innerValue = true;
        }
    }

    public getInputElement(): HTMLInputElement {
        return this.input;
    }

    @Watch('innerValue')
    onInnerValueChanged(newVal) {
        this.$emit('input', newVal);
    }

    @Watch('value')
    onValueChanged(newVal) {
        this.innerValue = newVal;
    }
}
