import { omit } from 'lodash';

type ObserverCallback<E> = (event: E) => void;

interface Observer<E, K extends keyof any = string> {
    observe: (callback: ObserverCallback<E>) => () => void
    stopObserving: (callback: ObserverCallback<E>) => void
    stopObservingForKey: (key: K) => void
    observeForKey: (key: K, callback: ObserverCallback<E>) => void
    dispatchAll: (event?: E) => void
    dispatchByKey: (key: K, event?: E) => void
    dispatchByKeys: (keys: K[], event?: E) => void
}

export default function createObserver<E, K extends keyof any = string>(): Observer<E, K> {
    type ObserverCallback = (event: E) => void;

    const observers: ObserverCallback[] = [];
    let observersByKey: Partial<Record<K, ObserverCallback[]>> = {};

    const dispatchByKey = (key: K, event: E) => {
        observersByKey[key]?.forEach(cb => cb(event));
    };

    const stopObserving = (callback: ObserverCallback) => {
        const key = observers.indexOf(callback);
        if (key !== -1) observers.splice(key, 1);
    };

    const stopObservingForKey = (key: K) => {
        observersByKey = omit(observersByKey, key);
    };

    return {
        observe(callback: ObserverCallback) {
            observers.push(callback);

            return () => stopObserving(callback);
        },
        stopObserving,
        stopObservingForKey,
        observeForKey(key: K, callback: ObserverCallback) {
            if (!observersByKey[key]) {
                observersByKey[key] = [];
            }

            observersByKey[key]?.push(callback);
            return () => stopObservingForKey(key);
        },
        dispatchAll(event: E): void {
            const allObservers = [
                ...observers,
            ];

            const observersByKeyFlat: ObserverCallback[][] = Object.values(observersByKey);

            observersByKeyFlat.forEach(_observers => allObservers.push(..._observers));
            allObservers.forEach(cb => cb(event));
        },
        dispatchByKey,
        dispatchByKeys(keys: K[], event: E): void {
            keys.forEach((key) => dispatchByKey(key, event));
        },
    };
}
