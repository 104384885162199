























































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { TranslateMixin } from '@/util/translate';

@Component
export default class FFormItem extends Mixins(TranslateMixin) {

    @Prop({ required: false, type: Boolean, default: true })
    showLabel: boolean;

    @Prop()
    errorMessages: any;

    @Prop({ type: Boolean, default: false, required: false })
    required: boolean;

    @Prop({ type: String, default: null, required: false })
    editLinkRoute: string | null;

    @Prop({ type: Boolean, default: false })
    noSpace: boolean;

    @Prop({ type: Object, default: null, required: false })
    listEntityLink!: ListEntityLink|null;

    @Prop({ type: Object, default: null, required: false })
    updateEntityLink!: UpdateEntityLink|null;

    get hasLabel(): boolean {
        return typeof this.$attrs.label !== 'undefined' && this.$attrs.label.length > 0;
    }
}
