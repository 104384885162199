import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { formatCurrency, formatNumber } from '@/module/shared/general/utils/formatUtils';

@Component
export default class FormatMixin extends Vue {

    formatNumber(input: string | number | null, minimumFractionDigits = 0, maximumFractionDigits = 4, useGrouping = false): string {
        return formatNumber(input, minimumFractionDigits, maximumFractionDigits, useGrouping);
    }

    formatCurrency(input: string | number | null, isoCode: string, minimumFractionDigits = 0, maximumFractionDigits = 2, useGrouping = false): string {
        return formatCurrency(input, isoCode, minimumFractionDigits, maximumFractionDigits, useGrouping);
    }

}
