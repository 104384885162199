var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("validation-provider", {
    attrs: {
      tag: "div",
      vid: _vm.vid,
      name: _vm.translate(_vm.label),
      rules: _vm.rules
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(_ref) {
            var errors = _ref.errors
            return _c(
              "f-form-item",
              {
                attrs: {
                  "error-messages": errors,
                  label: _vm.label,
                  "show-label": _vm.$attrs["show-label"],
                  required: _vm.required,
                  "edit-link-route": _vm.editLinkRoute,
                  "list-entity-link": _vm.listEntityLink,
                  "update-entity-link": _vm.updateEntityLink,
                  "no-space": _vm.$attrs["no-space"]
                }
              },
              [
                _c("vue-selectize", {
                  ref: "dropdown",
                  attrs: {
                    id: _vm.id,
                    name: _vm.name,
                    options: _vm.options,
                    "value-field": _vm.optionValue,
                    "search-field": _vm.searchField,
                    "disabled-field": _vm.disabledField,
                    label: _vm.optionLabel,
                    placeholder: _vm.$t(_vm.placeholder),
                    disabled: _vm.disabled,
                    multiple: _vm.$attrs.multiple,
                    "allow-add": _vm.allowCreate,
                    "lazy-loaded": _vm.lazyLoaded,
                    "lazy-load-trigger": _vm.lazyLoadTrigger
                  },
                  on: {
                    "change-object": function($event) {
                      return _vm.$emit("change-object", $event)
                    },
                    "load-lazy-choices": function($event) {
                      return _vm.$emit("load-lazy-choices")
                    }
                  },
                  model: {
                    value: _vm.innerValue,
                    callback: function($$v) {
                      _vm.innerValue = $$v
                    },
                    expression: "innerValue"
                  }
                }),
                _vm._v(" "),
                _vm.helpText
                  ? _c("f-form-help-text", {
                      attrs: {
                        text: _vm.helpText,
                        icon: _vm.helpIcon
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm._t("below-input")
              ],
              2
            )
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }