var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "form-group",
      class: {
        "has-error": _vm.errorMessages && _vm.errorMessages.length,
        "m-0 p-0": _vm.noSpace
      }
    },
    [
      _vm.showLabel && (!_vm.noSpace || _vm.hasLabel)
        ? _c(
            "label",
            {
              class: {
                "control-label": true,
                required: _vm.required
              }
            },
            [_vm._v(_vm._s(_vm.translate(_vm.$attrs.label)))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.listEntityLink || _vm.updateEntityLink
        ? _c(
            "div",
            {
              staticClass: "btn-group btn-group-xs float-right",
              attrs: {
                role: "group"
              }
            },
            [
              _vm.listEntityLink
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-link btn-xs",
                      attrs: {
                        type: "button",
                        href: _vm.listEntityLink.route,
                        tabindex: "99999"
                      }
                    },
                    [
                      _c("span", {
                        staticClass: "fa fa-fw fa-list"
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.updateEntityLink
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-link btn-xs",
                      attrs: {
                        type: "button",
                        "data-field": _vm.updateEntityLink.fieldName,
                        "data-route": _vm.updateEntityLink.route,
                        "data-params": _vm.updateEntityLink.params,
                        tabindex: "99999"
                      }
                    },
                    [
                      _c("span", {
                        staticClass: "fa fa-fw fa-pencil"
                      })
                    ]
                  )
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.errorMessages && _vm.errorMessages.length
        ? _c(
            "div",
            {
              staticClass: "form-text"
            },
            [
              _c(
                "ul",
                {
                  staticClass: "list-unstyled"
                },
                _vm._l(_vm.errorMessages, function(error, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "text-danger"
                    },
                    [
                      _c("span", {
                        staticClass: "fa fa-fw fa-exclamation-triangle"
                      }),
                      _vm._v(" " + _vm._s(error) + "\n            ")
                    ]
                  )
                }),
                0
              )
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }