































































































import { Component, Mixins, Prop, Watch, Ref } from 'vue-property-decorator';
import { TranslateMixin } from '@/util/translate';
import { ValidationProvider } from 'vee-validate';
import FFormItem from '@/components/form/f-form-item.vue';
import KeyUpEvent = JQuery.KeyUpEvent;
import FormElementMixin from '@/components/form/FormElementMixin.vue';
import FFormHelpText from '@/components/form/f-form-help-text.vue';
import FormatMixin from '@/module/shared/general/mixins/FormatMixin';
import {endsWith, omit, pick} from 'lodash';
import { parseInputFloat } from '@/util/global';

export enum FormatType {
    NUMBER = 'number',
}

@Component({
    components: { ValidationProvider, FFormItem, FFormHelpText },
})
export default class FInput extends Mixins(FormElementMixin, TranslateMixin, FormatMixin) {
    @Ref()
    input!: HTMLInputElement;

    @Prop()
    value: any;

    @Prop({ required: false, type: Boolean, default: true })
    showLabel!: boolean;

    @Prop({ type: String, default: null, required: false })
    formatType!: FormatType|null;

    @Prop({ type: Boolean, default: false })
    invalid!: Boolean

    @Prop()
    errorMessages: any;

    @Prop({ type: Boolean, default: false })
    initialFocus!: boolean;

    @Prop({ type: Boolean, default: false })
    isValid!: boolean;

    innerValue?: string = '';

    mounted() {
        if (this.initialFocus) {
            this.focus();
        }
    }

    get inputClasses() {
        return Object.assign(
            {
                'form-control': true,
                'form-control-with-append': this.$attrs.append,
                'is-invalid': this.errorMessages && this.errorMessages.length > 0,
                'is-valid': this.isValid
            },
            this.inputClass,
        );
    }

    public getInputElement(): HTMLInputElement {
        return this.input;
    }

    get inputAttrs(): Record<string, any> {
        const inheritedAttrs = ['step', 'readonly', 'type', 'placeholder'];

        const attrs = Object.assign({
            id: this.id,
            name: this.name,
            class: this.inputClasses,
            disabled: this.disabled,
            required: this.required,
        }, pick(this.$attrs, inheritedAttrs));

        // Inherit data attributes.
        for (const key in this.$attrs) {
            if (key.startsWith('data-')) {
                attrs[key] = this.$attrs[key];
            }
        }

        if (!attrs.name) {
            return omit(attrs, 'name');
        }

        return attrs;
    }

    formatDisplayValue(value: any) {
        switch (this.formatType) {
            case FormatType.NUMBER:
                const parsed = parseInputFloat(value);

                if (isNaN(parsed)) {
                    return value;
                }

                return this.$formatNumber(parsed, 0, 10);
            default:
                return value;
        }
    }

    focus() {
        this.input.focus();
    }

    formatVModel(value: any) {
        switch (this.formatType) {
            case FormatType.NUMBER:
                if (value === '-') {
                    return value;
                }

                if (typeof value === 'string' && value.trim() === '') {
                    return '';
                }

                return parseInputFloat(value);
            default:
                return value;
        }
    }

    @Watch('innerValue')
    onInnerValueChanged(newVal): void {
        const value = this.formatVModel(newVal);

        this.$emit('input', value);
        this.$emit('change', value);
    }

    @Watch('value', { immediate: true })
    onValueChanged(newVal): void {
        if (this.innerValue === newVal || this.innerValue === this.formatVModel(newVal)) {
            return;
        }

        this.innerValue = this.formatVModel(newVal);
    }

    onKeyUp(evt: KeyUpEvent): void {
        this.$emit('keyup', evt);
    }

    onKeyDown(evt): void {
        this.$emit('keydown', evt);
    }
}
