
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class FormElementMixin extends Vue {
    @Prop({ type: String, required: false })
    id!: string|undefined;

    @Prop({ type: Boolean, default: false, required: false })
    required!: boolean;

    @Prop({ type: String, default: '' })
    label!: string;

    @Prop({ type: String, default: '' })
    name!: string;

    @Prop({ type: String, default: '' })
    vid!: string;

    @Prop({ type: [Object, String], default: '' })
    rules!: string | Record<string, string[]|string>;

    @Prop({ type: [Object, String], default: '' })
    inputClass!: Record<string, boolean>;

    @Prop({ type: Boolean, default: false })
    disabled!: boolean;

    @Prop({ type: String, required: false, default: null })
    helpText!: string | null;

    @Prop({ type: String, required: false, default: null })
    helpIcon!: string | null;

    get formElementStateProps() {
        return {
            disabled: this.disabled,
            required: this.required,
            append: this.$attrs.append,
            prepend: this.$attrs.prepend,
            'no-space': this.$attrs['no-space'],
        };
    }

    getInputClassAsArray(): Array<string>  {
        const classNames = Object.keys(this.inputClass);
        const classConditions = Object.values(this.inputClass);

        return classNames.filter((className, key) => classConditions[key]);
    }
}
